/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { useEffect } from 'react';
import { notifyAirbrake } from 'lib/config/airbrake';
import { useStripe } from '@stripe/react-stripe-js';
import {
  GOOGLE_PAY,
  APPLE_PAY,
} from 'views/reservation-path-module/CheckoutView/utils/paymentMethodTypes';

const useInitializeStripe = (
  initialStripePaymentCards,
  setstripePaymentCards,
  setPaymentRequest,
  getGoogleOrApplePaymentBody,
  selectedCountry,
  setShowCarouselHeader,
) => {
  // init
  const stripe = useStripe();
  let tempStripeCards;

  // handlers
  const checkPaymentMethodSysAllowence = (paymentIdentifier) => initialStripePaymentCards.find(
    (card) => card.identifier == paymentIdentifier,
  );

  const checkCanMakePaymentByStripe = (pr) => {
    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (!result || (!result?.googlePay && !result?.applePay)) {
        tempStripeCards = initialStripePaymentCards.filter(
          (card) => card.identifier != GOOGLE_PAY && card.identifier != APPLE_PAY,
        );
      } else {
        if (!result.googlePay) {
          tempStripeCards = initialStripePaymentCards.filter(
            (card) => card.identifier != GOOGLE_PAY,
          );
        }
        if (!result.applePay) {
          tempStripeCards = initialStripePaymentCards.filter(
            (card) => card.identifier != APPLE_PAY,
          );
        }
      }
      if (tempStripeCards.length <= 1) setShowCarouselHeader(false);
      setstripePaymentCards(tempStripeCards);
      setPaymentRequest(pr);
    });
  };

  const initiateStripe = async (disableWallets) => {
    const googleOrApplePaymentBody = await getGoogleOrApplePaymentBody();
    const pr = stripe.paymentRequest({
      country: 'US', // This is the Stripe account country
      ...googleOrApplePaymentBody,
      requestPayerName: true,
      requestPayerEmail: true,
      disableWallets,
    });
    checkCanMakePaymentByStripe(pr);
  };

  // useEffects
  useEffect(() => {
    if (!stripe) return;
    // check if our system allow this reservation use google pay or apple pay
    const hasGooglePayMethod = checkPaymentMethodSysAllowence(GOOGLE_PAY);
    const hasApplePayMethod = checkPaymentMethodSysAllowence(APPLE_PAY);

    if (hasApplePayMethod || hasGooglePayMethod) {
      // prepare what payment type we should diabled
      // we always disable link for now
      const disableWallets = ['link'];
      if (!hasApplePayMethod) disableWallets.push('applePay');
      if (!hasGooglePayMethod) disableWallets.push('googlePay');

      initiateStripe(disableWallets).catch((error) => {
        notifyAirbrake(error);
      });
    } else {
      tempStripeCards = initialStripePaymentCards.filter(
        (card) => card.identifier != GOOGLE_PAY && card.identifier != APPLE_PAY,
      );
      if (tempStripeCards.length <= 1) setShowCarouselHeader(false);
      setstripePaymentCards(tempStripeCards);
    }
  }, [stripe]);

  return { stripe };
};

export default useInitializeStripe;
