const useUpdateStripePaymentRequest = (
  getGoogleOrApplePaymentBody,
  paymentRequest,
) => {
  const updateStripePaymentRequest = async (allowPartialPayments) => {
    const googleOrApplePaymentBody = await getGoogleOrApplePaymentBody(
      allowPartialPayments,
    );
    paymentRequest.update({
      country: 'US', // This is the Stripe account country,
      ...googleOrApplePaymentBody,
    });
  };

  return { updateStripePaymentRequest };
};

export default useUpdateStripePaymentRequest;
