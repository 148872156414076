import axios, { API } from 'lib/config/axios';
import useCookies from 'views/common/hooks/useCookies';
import { EXTERNAL_PAYMENT_STATUS } from 'lib/utils/CONSTANTS';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { useSelector } from 'react-redux';
import use403ResponseHandler from 'views/reservation-path-module/common/hooks/use403ResponseHandler';

const useUpdateExternalPaymentStatus = () => {
  // init
  const appState = useSelector((state) => state.globalData.appState);
  const handleErrorResponse = useErrorsHandler();
  const [cookies] = useCookies();
  const reservationIdentifier = cookies.reservation;
  const handle403Response = use403ResponseHandler();

  const updateExternalPaymentStatus = async (
    paymentAttributes,
    handleSuccessfulCallback = () => {},
    handleFailureCallback = () => {},
    expectedStatuses = [],
  ) => {
    let clientExpectedStatuses = [];
    if (expectedStatuses.length == 0) {
      clientExpectedStatuses = [
        EXTERNAL_PAYMENT_STATUS.pending_confirmation,
        EXTERNAL_PAYMENT_STATUS.success,
      ];
    } else {
      clientExpectedStatuses = expectedStatuses;
    }

    await axios
      .put(
        API.reservations.payments.externalPayment.replace(
          '{reservation_id}',
          reservationIdentifier,
        ),
        {
          authenticity_token: appState.authenticity_token,
          payment_attributes: {
            payment_intent_client_secret: paymentAttributes.clientSecret,
            payment_intent_id: paymentAttributes.paymentIntentId,
            amount: paymentAttributes.amount,
            currency_code: paymentAttributes.currency_code,
          },
        },
      )
      .then((res) => {
        if (clientExpectedStatuses.includes(res.data.status)) {
          handleSuccessfulCallback();
        } else {
          localStorage.setItem('paymentFailed', true);
          handleFailureCallback(res);
        }
      })
      .catch((error) => {
        if (error.response.status == '403') {
          if (
            ['ER0001', 'ER0002', 'ER0003', 'ER0007', 'ER0008'].includes(
              error.response.data.error_code,
            )
          ) {
            handle403Response(error);
          }
        } else {
          handleErrorResponse(error);
        }
        handleFailureCallback(error.response);
      });
  };
  return { updateExternalPaymentStatus };
};

export default useUpdateExternalPaymentStatus;
