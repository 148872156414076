import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ImgBox } from 'views/common/components/UI/ImgBox';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme, numOfCards }) => ({
  '&.PMcarsoul': {
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      padding: numOfCards > 3 ? theme.spacing(0, 5.8) : null,
    },
  },
}));

export const StyledPaymentTypeBox = styled(Box)(
  ({ theme, selected, disabled }) => ({
    margin: theme.spacing(0, 0.625, 0.125, 0.625),
    border: `1px solid ${theme.palette.grey[50]}`,
    boxShadow: selected
      ? '0px 0px 4px rgba(0, 0, 0, 0.25)'
      : '0px 0px 2px rgba(0, 0, 0, 0.1)',
    height: '99%',
    color: selected && theme.palette.secondary.main,
    borderColor: selected && theme.palette.primary.main,
    opacity: disabled && 0.5,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
    },
  }),
);

export default StyledBox;

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '&.react-multi-carousel-list': {
    color: theme.palette.grey['300'],
    position: 'unset',
  },
  '& .react-multi-carousel-item': {
    [theme.breakpoints.down('lg')]: {
      '&:first-child': {
        '& .SPCarouselItem': {
          margin: theme.spacing(0, 1.25, 0.1, 0),
        },
      },
      '&:last-child': {
        '& .SPCarouselItem': {
          margin: theme.spacing(0, 0.375, 0.1, 1.25),
        },
      },
      '&:only-child': {
        '& .SPCarouselItem': {
          margin: theme.spacing(0, 0.375, 0, 0),
        },
      },
    },
  },
  '& .react-multiple-carousel__arrow': {
    transition: 'none',
    height: '100% ',
    background: theme.palette.common.white, // bg not bgColor to overwrite the plugin style
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '0',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      background: theme.palette.common.white, // bg not bgColor to overwrite the plugin style
      '&.react-multiple-carousel__arrow::before': {
        color: theme.palette.grey[300],
      },
    },
    '&:disabled': {
      background: theme.palette.grey.A000,
      '&.react-multiple-carousel__arrow::before': {
        color: theme.palette.grey[200],
      },
    },
  },
  '& .react-multiple-carousel__arrow--right': {
    right: '0',
  },
  '& .react-multiple-carousel__arrow--left': {
    left: '0',
  },
  '& .react-multiple-carousel__arrow::before': {
    color: theme.palette.grey[900],
    fontSize: theme.spacing(1.5),
    fontWeight: '700',
  },
  '& .MuiTypography-subtitle1': {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(5),
    },
  },
}));
