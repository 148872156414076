import { useEffect, useState } from 'react';
import {
  GOOGLE_PAY,
  APPLE_PAY,
} from 'views/reservation-path-module/CheckoutView/utils/paymentMethodTypes';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import { useDispatch, useSelector } from 'react-redux';
import useUpdateExternalPaymentStatus from 'views/reservation-path-module/common/hooks/useUpdateExternalPaymentStatus';
import { useAdjustPaidCredits } from 'views/reservation-path-module/CheckoutView/hooks';
import useInitializeStripe from './useInitializeStripe';
import useUpdateStripePaymentRequest from './useUpdateStripePaymentRequest';

const useHandleGoogleOrApplePayments = (
  initialStripePaymentCards,
  depositData,
  clientSecret,
  postCheckout,
  getGoogleOrApplePaymentBody,
  resetStripeStartPaymentFlag,
  paymentRequest,
  setPaymentRequest,
  currentPaymentMethod,
  setShowCarouselHeader,
  paymentFailureCallback,
  reservation,
) => {
  // init
  const currentPaymentType = currentPaymentMethod.identifier;
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.globalData.appState);
  const selectedCountry = appState.current_country.iso2.toUpperCase();
  const button = document.getElementById('apple-payment-submit');

  // states
  const [stripePaymentCards, setstripePaymentCards] = useState([]);
  const [paymentEventCreated, setPaymentEventCreated] = useState(false);

  // hooks
  const updateExternalSuccessCallback = async () => {
    await postCheckout();
  };
  const { updateExternalPaymentStatus } = useUpdateExternalPaymentStatus();
  const { stripe } = useInitializeStripe(
    initialStripePaymentCards,
    setstripePaymentCards,
    setPaymentRequest,
    getGoogleOrApplePaymentBody,
    selectedCountry,
    setShowCarouselHeader,
  );
  const { updateStripePaymentRequest } = useUpdateStripePaymentRequest(
    getGoogleOrApplePaymentBody,
    paymentRequest,
    selectedCountry,
  );
  const { adjustPaidCredits } = useAdjustPaidCredits();

  // handlers
  const handlePaymentFailure = async (ev, snackBarErr) => {
    await paymentFailureCallback();
    dispatch(setLoading(false));
    button && (button.disabled = false);
    dispatch(
      setSnackbar({
        open: true,
        severity: 'error',
        message: snackBarErr,
      }),
    );
    resetStripeStartPaymentFlag();
    ev.complete('fail');
  };

  const handlePaymentSuccess = async (paymentIntentId, ev) => {
    button && (button.disabled = false);
    await updateExternalPaymentStatus(
      { paymentIntentId, clientSecret },
      updateExternalSuccessCallback,
    );
    ev.complete('success');
  };
  const payByPaymentMethodHandler = async (ev) => {
    dispatch(setLoading(true));
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      { payment_method: ev.paymentMethod.id },
      { handleActions: false },
    );
    if (confirmError) {
      handlePaymentFailure(ev, confirmError.message);
    } else if (paymentIntent.status === 'requires_action') {
      const { error } = await stripe.confirmCardPayment(clientSecret);
      if (error) {
        // The payment failed -- ask your customer for a new payment method.
        handlePaymentFailure(ev, error.message);
      } else {
        handlePaymentSuccess(paymentIntent.id, ev);
      }
    } else {
      handlePaymentSuccess(paymentIntent.id, ev);
    }
  };
  const cancelPaymentHandler = async () => {
    button && (button.disabled = false);
    await adjustPaidCredits(reservation.identifier);
    resetStripeStartPaymentFlag();
    dispatch(setLoading(false));
  };
  // useEffects
  useEffect(() => {
    if (
      paymentRequest
      && clientSecret
      && (currentPaymentType == GOOGLE_PAY || currentPaymentType == APPLE_PAY)
      && !paymentEventCreated
    ) {
      setPaymentEventCreated(true);
      paymentRequest.on('cancel', () => {
        cancelPaymentHandler();
      });

      // After the service evaulates the payment methods visible
      paymentRequest.on('paymentmethod', (ev) => {
        payByPaymentMethodHandler(ev);
      });
    }
    return () => {
      if (
        paymentRequest
        && clientSecret
        && (currentPaymentType == GOOGLE_PAY || currentPaymentType == APPLE_PAY)
      ) {
        paymentRequest.off('cancel', () => {
          cancelPaymentHandler();
        });
        paymentRequest.off('paymentmethod', (ev) => {
          payByPaymentMethodHandler(ev);
        });
      }
    };
  }, [paymentRequest, clientSecret]);

  useEffect(() => {
    if (
      !paymentRequest
      || (currentPaymentType !== GOOGLE_PAY && currentPaymentType !== APPLE_PAY)
    ) return;

    updateStripePaymentRequest(currentPaymentMethod.supports_partial_payments);
  }, [depositData.chosenAmount, currentPaymentMethod]);

  // when initialStripePaymentCards gets updated update stripePaymentCards with it
  useEffect(() => {
    if (stripePaymentCards.length > 0) {
      setstripePaymentCards((prev) => {
        const newStripePaymentCards = prev.map((prevPaymentCard) => initialStripePaymentCards.find(
          (paymentCard) => paymentCard.identifier === prevPaymentCard.identifier,
        ));
        return newStripePaymentCards;
      });
    }
  }, [initialStripePaymentCards]);
  return { stripePaymentCards };
};

export default useHandleGoogleOrApplePayments;
