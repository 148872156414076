import { useSelector } from 'react-redux';

import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

const useAdjustPaidCredits = () => {
  const { appState } = useSelector((state) => state.globalData);
  const handleErrorResponse = useErrorsHandler();

  const adjustPaidCredits = async (reservationIdentifier) => {
    try {
      await axios.put(
        API.reservations.payments.adjustPaidCredits.replace(
          '{reservation_id}',
          reservationIdentifier,
        ),
        {
          authenticity_token: appState.authenticity_token,
        },
      );
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return {
    adjustPaidCredits,
  };
};

export { useAdjustPaidCredits };
