import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(2),
  flexDirection: 'column',
  '& .RMTitle': {
    color: theme.palette.secondary.main,
  },
  '& .RMCredits': {
    padding: theme.spacing(1.25),
    borderRadius: theme.typography.pxToRem(7),
    backgroundColor: theme.palette.grey.A000,
  },
  '& .RMCreditsTitle': {
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  '& .RMCreditsDesc': {
    color: theme.palette.secondary.main,
  },
  '& .RMAlertLabel': {
    fontStyle: 'italic',
  },
  '& .RMRadioLabel': {
    '& .MuiTypography-root': {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.secondary.main,
      fontWeight: 500,
    },
    '&.RMRadioLabelSelected': {
      '& .MuiTypography-root': {
        color: theme.palette.secondary.dark,
      },
    },
  },
  '& .RMRefundCreditNote': {
    color: theme.palette.secondary.light,
  },
  '& .RMRMRefundCreditNoteIcon': {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
    color: theme.palette.secondary.light,
  },
  '& .RMRMRefundCreditNoteBtn': {
    padding: 0,
    position: 'relative',
    top: theme.typography.pxToRem(-2),
    marginLeft: theme.spacing(1),
  },
}));

export default StyledBox;
