import { Box, alpha, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme, validPhone }) => ({
  '&.IPphoneNumber': {
    position: 'relative',
    '& .intl-tel-input.allow-dropdown': {
      width: '100%',
      '& input[type="tel"]': {
        height: theme.typography.pxToRem(56),
        borderRadius: '0px',
        border: `1px solid ${theme.palette.grey[200]}`,
        width: '100%',
        paddingLeft: theme.typography.pxToRem(88),
        fontSize: theme.typography.pxToRem(16),
        borderColor: validPhone === false && theme.palette.error.main,

        '&:focus-visible,&:focus': {
          outline: 'none',
          fontSize: '16px',
          borderBottom: '2px solid',
          paddingTop: 2,
          borderBottomColor: theme.palette.grey[600],
        },
      },

      '& .selected-flag': {
        borderRight: `1px solid ${alpha(theme.palette.common.black, 0.23)}`,
        width: theme.typography.pxToRem(76),
      },
      '& .arrow': {
        fontSize: theme.typography.pxToRem(10),
        transform: 'scale(1.5, 1)',
        color: theme.palette.secondary.light,
      },
    },
    '& .arrow': { pointerEvents: 'none' },
  },
  '& .IPvalid': {
    color: theme.palette.success.main,
  },
  '& .IPinvalid': {
    color: theme.palette.error.main,
  },
  '& .IPicon': {
    position: 'absolute',
    right: theme.typography.pxToRem(12),
    top: theme.typography.pxToRem(15),
  },
}));

export default StyledBox;
