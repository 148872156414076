/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import StyledBox, {
  StyledCarousel,
  StyledImgBox,
  StyledPaymentTypeBox,
} from 'views/common/components/UI/PaymentMethodTypes/PaymentMethodTypes.styles';
import { useSelector } from 'react-redux';
import Typography500 from 'views/common/components/UI/Typography500';
import useHandleGoogleOrApplePayments from 'views/reservation-path-module/CheckoutView/hooks/useHandleGoogleOrApplePayments';
import { PaymentMethodTypesSkeleton } from './PaymentMethodTypesSkeleton';

const responsive = {
  largedesktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 3,
    partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    partialVisibilityGutter: 13,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    partialVisibilityGutter: 13,
  },
};

const PaymentMethodTypes = (props) => {
  // props
  const {
    initialStripePaymentCards,
    handleStripePaymentTypeChange,
    currentPaymentMethod,
    depositData,
    clientSecret,
    postCheckout,
    getGoogleOrApplePaymentBody,
    resetStripeStartPaymentFlag,
    paymentRequest,
    setPaymentRequest,
    setShowCarouselHeader,
    paymentFailureCallback,
    reservationData,
  } = props;

  // init
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  // hooks
  const { stripePaymentCards } = useHandleGoogleOrApplePayments(
    initialStripePaymentCards,
    depositData,
    clientSecret,
    postCheckout,
    getGoogleOrApplePaymentBody,
    resetStripeStartPaymentFlag,
    paymentRequest,
    setPaymentRequest,
    currentPaymentMethod,
    setShowCarouselHeader,
    paymentFailureCallback,
    reservationData,
  );

  if (stripePaymentCards.length == 0) {
    return <PaymentMethodTypesSkeleton />;
  }

  if (stripePaymentCards.length == 1) {
    return <></>;
  }

  return stripePaymentCards.length > 1 ? (
    <StyledBox className="PMcarsoul" numOfCards={stripePaymentCards.length}>
      <StyledCarousel
        partialVisible={stripePaymentCards.length > 1}
        renderArrowsWhenDisabled
        swipeable
        draggable={false}
        ssr={isCrawler}
        deviceType={deviceType}
        keyBoardControl
        arrows={!isTablet}
        autoPlay={false}
        shouldResetAutoplay={false}
        containerClass="carousel-container"
        responsive={responsive}
        customTransition="transform 200ms ease-in"
        showDots={false}>
        {stripePaymentCards.map((item) => (
          <StyledPaymentTypeBox
            selected={item.identifier === currentPaymentMethod.identifier}
            disabled={item.disabled}
            py={2.35}
            px={2}
            key={item.name}
            onClick={() => handleStripePaymentTypeChange(item)}>
            <StyledImgBox
              imgHeight="1.75rem"
              imgWidth="2.5rem"
              image={{
                src: `/imgs/payments/payment-methods/${item.identifier}.svg`,
                alt: item.name,
              }}
              imgObjectFit="contain"
            />
            <Typography500 variant="subtitle1" component="p" mt={2}>
              {item.name}
            </Typography500>
          </StyledPaymentTypeBox>
        ))}
      </StyledCarousel>
    </StyledBox>
  ) : (
    <PaymentMethodTypesSkeleton />
  );
};

export { PaymentMethodTypes };
