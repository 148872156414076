/* eslint-disable no-restricted-syntax */

// this function is only for omitting top level properties
const omit = (originalObject = {}, keysToOmit = []) => {
  const clonedObject = { ...originalObject };
  for (const path of keysToOmit) {
    delete clonedObject[path];
  }
  return clonedObject;
};
export default omit;
