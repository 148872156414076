/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import StyledBox from 'views/common/components/Logical/InternationalPhone/InternationalPhone.styles';
import IntlTelInput from 'react-intl-tel-input';
import { useTranslation } from 'next-i18next';
import 'react-intl-tel-input/dist/main.css';
import useCookies from 'views/common/hooks/useCookies';
import { countries } from 'lib/data/countries';

const InternationalPhone = (props) => {
  const {
    disabled,
    value: phone,
    handleChange,
    handleBlur = () => {},
    error,
    helperText,
    name,
    id,
    skipValidation = false,
  } = props;
  const { t } = useTranslation();
  const [validPhone, setValidPhone] = useState();
  const [cookies] = useCookies();
  const handlePhoneNumberBlur = (status, value, number, countryData) => {
    if (status) {
      handleBlur({
        value,
        number: number.replace(/-|\+|\(|\)| /g, ''),
        countryCode: countryData.iso2,
        phoneCode: countryData.dialCode,
      });
      setValidPhone(true);
    } else if (value) {
      setValidPhone(false);
    } else {
      setValidPhone(null);
    }
  };

  const handlePhoneNumberChange = (value, number, countryData, status) => {
    const regex = /[a-zA-Z!@#$%^&*_=[\]{};':"\\|,.<>/?]/;
    if (!skipValidation && regex.test(value)) return;
    handleChange({
      value,
      number,
      countryCode: countryData.iso2,
      phoneCode: countryData.dialCode,
      status,
    });
    // handlePhoneNumberBlur(status, value, number, countryData);
  };
  const handleCountrySelect = (value, countryData, number, status) => {
    const regex = /[a-zA-Z!@#$%^&*_+=[\]{};':"\\|,.<>/?]/;
    if (regex.test(value)) return;
    handleChange({
      value,
      number,
      countryCode: countryData.iso2,
      phoneCode: countryData.dialCode,
      status,
    });
  };

  useEffect(() => {
    if (error) {
      setValidPhone(false);
    }
  }, [error]);

  const getPrefreedCountries = () => {
    const preferredCountries = [];
    if (phone) {
      const selectedCountry = countries.find((country) => phone.startsWith(`+${country.phone}`));
      selectedCountry
        && preferredCountries.push(selectedCountry.code.toLowerCase());
    }
    if (cookies?.geo_location_v2?.country_code) {
      preferredCountries.push(
        cookies.geo_location_v2.country_code.toLowerCase(),
      );
    }
    if (!preferredCountries.includes('us')) {
      preferredCountries.push('us');
    }
    return preferredCountries;
  };

  return (
    <StyledBox id={id} className="IPphoneNumber" validPhone={validPhone}>
      <IntlTelInput
        autoComplete="tel"
        format
        name={name}
        required
        disabled={disabled}
        preferredCountries={getPrefreedCountries()}
        value={phone || ''}
        onSelectFlag={handleCountrySelect}
        onPhoneNumberChange={(status, value, countryData, number) => handlePhoneNumberChange(value, number, countryData, status)}
        onPhoneNumberBlur={(status, value, countryData, number) => handlePhoneNumberBlur(status, value, number, countryData)}
      />
      {validPhone === true && (
        <Box className="IPvalid IPicon">
          <CheckIcon />
        </Box>
      )}
      {validPhone === false && (
        <>
          <Box className="IPinvalid IPicon">
            <CloseIcon />
          </Box>
          <Typography variant="h6" component="p" className="IPinvalid">
            {helperText || t('common_phone:in_valid_phone_number')}
          </Typography>
        </>
      )}
    </StyledBox>
  );
};

export { InternationalPhone };
