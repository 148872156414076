import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

const PaymentMethodTypesSkeleton = () => (
  <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={230} />
      </Grid>
    </Grid>
  </>
);

export { PaymentMethodTypesSkeleton };
