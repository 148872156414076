import React, { Fragment, useState } from 'react';
import StyledBox from 'views/common/components/UI/RefundMethods/RefundMethods.styles';
import { useTranslation } from 'next-i18next';
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Tooltip,
  IconButton,
  Alert,
} from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography700 } from 'views/common/components';
import InfoIcon from '@mui/icons-material/Info';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import { REFUNDS_METHODS } from 'views/common/components/UI/RefundMethods/utils/CONSTANTS';

const RefundMethods = (props) => {
  const {
    membershipInfo,
    rewardsInfo,
    refundMethodInfo,
    email,
    refundMethods = [],
  } = props;
  const { t } = useTranslation('fe_er_common_refund_methods');
  const [selectedMethod, setSelectedMethod] = useState(
    refundMethods[0]?.identifier,
  );

  const handleRadioChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const renderStoreCreditNote = (ml = null) => REFUNDS_METHODS[selectedMethod] === REFUNDS_METHODS.stored_credit && (
  <Typography variant="h6" className="RMRefundCreditNote" ml={ml && 4}>
    {t('fe_er_common_refund_methods:store_credit_emailed_at', {
      email,
    })}
    <Tooltip
      title={t('fe_er_common_refund_methods:store_credit_tip', {
        email,
      })}>
      <IconButton className="RMRMRefundCreditNoteBtn">
        <InfoOutlinedIcon className="RMRMRefundCreditNoteIcon" />
      </IconButton>
    </Tooltip>
  </Typography>
  );

  return (
    <StyledBox>
      <Typography500 className="RMTitle" variant="h4">
        {t('fe_er_common_refund_methods:refund_method')}
      </Typography500>
      {refundMethods.length > 1 && (
        <Box className="RMCredits">
          <Typography500 className="RMTitle" variant="h5">
            {t('fe_er_common_refund_methods:select_refund_method')}
          </Typography500>
          <FormControl>
            <RadioGroup>
              {refundMethods.map((refundMethod) => (
                <Fragment key={refundMethod.identifier}>
                  <FormControlLabel
                    value={refundMethod.identifier}
                    checked={selectedMethod === refundMethod.identifier}
                    onChange={handleRadioChange}
                    control={<Radio color="info" />}
                    label={t(
                      `fe_er_common_refund_methods:${refundMethod.identifier}`,
                    )}
                    className={`RMRadioLabel ${
                      selectedMethod === refundMethod.identifier
                      && 'RMRadioLabelSelected'
                    }`}
                  />
                  {REFUNDS_METHODS[refundMethod.identifier]
                    === REFUNDS_METHODS.stored_credit && renderStoreCreditNote(4)}
                </Fragment>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      {refundMethodInfo.count > 0
        && REFUNDS_METHODS[selectedMethod] === REFUNDS_METHODS.payment_source && (
          <Box className="RMCredits">
            <Typography500 className="RMTitle" variant="h5">
              {t('fe_er_common_refund_methods:refund_on_same_payment_methods', {
                value: moneyFormat(Dinero(refundMethodInfo.amount)),
              })}
            </Typography500>
          </Box>
      )}
      {refundMethodInfo.count > 0
        && REFUNDS_METHODS[selectedMethod] === REFUNDS_METHODS.stored_credit && (
          <Box className="RMCredits">
            <Typography500 className="RMTitle" variant="h5">
              {t('fe_er_common_refund_methods:refund_store_credit', {
                value: moneyFormat(Dinero(refundMethodInfo.amount)),
              })}
            </Typography500>
            {renderStoreCreditNote()}
          </Box>
      )}
      {membershipInfo.count > 0 && (
        <Box className="RMCredits">
          <Typography500 className="RMCreditsTitle" variant="h5">
            {t('fe_er_common_refund_methods:membership_credit_title', {
              value: membershipInfo.count,
            })}
          </Typography500>
          <Typography variant="body2" className="RMCreditsDesc">
            {t('fe_er_common_refund_methods:membership_credit_desc')}
          </Typography>
        </Box>
      )}
      {rewardsInfo.count > 0 && (
        <Box className="RMCredits">
          <Typography500 className="RMCreditsTitle" variant="h5">
            {t('fe_er_common_refund_methods:reward_credit_title', {
              value: moneyFormat(Dinero(rewardsInfo.amount)),
            })}
          </Typography500>
          <Typography variant="body2" className="RMCreditsDesc">
            {t('fe_er_common_refund_methods:reward_credit_desc')}
          </Typography>
        </Box>
      )}
      {REFUNDS_METHODS[selectedMethod] === REFUNDS_METHODS.payment_source && (
        <Alert severity="info" icon={<InfoIcon fontSize="inherit" />}>
          <Typography700
            variant="body2"
            component="span"
            className="RMAlertLabel">
            {t('fe_er_common_refund_methods:alet_text_label')}
          </Typography700>
          <Typography variant="body2" component="span">
            {t('fe_er_common_refund_methods:alet_text')}
          </Typography>
        </Alert>
      )}
    </StyledBox>
  );
};

export { RefundMethods };
